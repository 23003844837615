@import url('https://fonts.googleapis.com/css2?family=Bungee:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@font-face {
  font-family: 'Century Gothic';
  src: local('Century Gothic'), local('CenturyGothic'),
       url('./fonts/CenturyGothic.ttf') format('truetype');
  font-weight: 400;
}

.font-bungee {
  font-family: 'Bungee', sans-serif;
}

.font-manrope {
  font-family: 'Manrope', sans-serif;
}

.font-centuryGothic {
  font-family: 'Century Gothic', sans-serif;
}

.text-0-5rem {
  font-size: 2rem;
}

@keyframes radiation {
  0% {
    box-shadow: 0 0 20px 8px rgba(255, 255, 255, 0.3);
  }
  50% {
    box-shadow: 0 0 45px 10px rgba(255, 255, 255, 0.6);
  }
  100% {
    box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.3);
  }
}

.animate-radiation {
  animation: radiation 3s ease-in-out infinite;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
